.formInput {
  width: 100%;
  min-height: 45px;
  font-size: 16px;
  padding: 8px 15px;
  color: #3a4c63;
  caret-color: #303030;
  outline: 0;
  border: 1px solid #b1bac4;
  background-color: #fff;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
}

.formInput::placeholder {
  color: #aaaaaa;
  opacity: 1;
}

.formInput::-moz-selection,
.formInput::selection {
  color: #0e91fd;
}

select.default {
  color: #aaa;
}

select {
  color: #282636;
}
