/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Caveat:700|Roboto:300,400,500,700&display=swap');

font-face {
  font-family: 'Area Normal';
  src: url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-Regular.woff2)
      format('woff2'),
    url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-Regular.woff)
      format('woff');
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: 'Area Normal';
  src: url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-Bold.woff2)
      format('woff2'),
    url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-Bold.woff)
      format('woff');
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: 'Area Normal';
  src: url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-Black.woff2)
      format('woff2'),
    url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-Black.woff)
      format('woff');
  font-display: swap;
  font-weight: 900;
}
@font-face {
  font-family: 'Area Normal';
  src: url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-RegularItalic.woff2)
      format('woff2'),
    url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/Area-RegularItalic.woff)
      format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'MADE Mirage';
  src: url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/made_mirage_bold-webfont.woff2)
      format('woff2'),
    url(https://www.givelify.com/wp-content/themes/givelify/assets/fonts/made_mirage_bold-webfont.woff)
      format('woff');
  font-display: swap;
  font-weight: 700;
}

* {
  outline: none;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

body {
  background: #f3f3f7;
  line-height: 20px;
  color: #999;
  font-size: 16px;
  font-family: Area Normal;
  font-weight: 400;
  height: auto;
  min-height: 100%;
  position: relative;
  padding-top: 12px;
}

button.MuiButton-root {
  text-transform: none;
}

.header-container {
  padding: 0 !important;
}

.mainpart {
  min-height: 328px;
}

.mainpart a {
  cursor: pointer;
}
.mainpart > div {
  box-shadow: 0px 2px 2px rgba(74, 108, 127, 0.12);
  border-radius: 32px 32px 32px 32px;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

img {
  height: auto;
  max-width: 100%;
  border: none;
  outline: none;
  transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}

* {
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

label {
  display: inline-block;
  margin-bottom: 8px;
}

.left-side {
  float: left;
}

.right-side {
  float: right;
}

.container {
  background: #fff;
}

.w-100 {
  display: block;
  width: 100%;
}

.poiter {
  cursor: pointer;
}

.dib {
  display: inline-block;
}

.df {
  display: flex;
}

.position-r {
  position: relative;
}

.text-left {
  text-align: left;
}

.height-auto {
  height: auto;
}

.height-auto-i {
  height: auto !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

/* .ReactModal__Body--open #root > div > div > div, .ReactModal__Body--open #root .banner-main, .ReactModal__Body--open.cvv-help-open .overlay-popup .ReactModal__Content {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
} */

/* .ReactModal__Body--open.cvv-help-open .ReactModalPortal:first-child .ReactModal__Overlay {
  background-color: transparent;
} */

.ReactModal__Body--open .overlay-popup {
  position: fixed;
  inset: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #f1f0f7cc;
}

/* .ReactModal__Body--open.cvv-help-open .overlay-popup {
  background-color: transparent;
} */

/* - Font Style  //-------------- */
h1,
h2,
h3,
h4,
h5,
h6,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #111924;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 10px;
}

a,
button,
button div {
  cursor: pointer !important;
}

/* - Spacer Style  //---------------- */
.plr-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.mlr_-6 {
  margin-left: -6px !important;
  margin-right: -6px !important;
}

.plr-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.mlr_-8 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.mlr_-10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.plr-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.mlr_-12 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}

.m-0 {
  margin: 0px !important;
}

.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb_-30 {
  margin-bottom: -30px;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}

.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  width: 100%;
  position: fixed;
  transition: all 1500ms ease-in 1500ms;
}

.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  width: 100%;
  transition: all 1500ms ease-out 1500ms;
}

.card-icon img {
  max-height: 22px;
}

.overlay {
  height: 100%;
  width: 100%;
  display: inline-block;
  position: fixed;
  left: 0;
  top: 0;
  cursor: default;
}

/* .mlr_-8.row {
  margin-bottom: 20px;
} */

.username {
  margin: 4px 8px 4px 12px;
  display: inline-block;
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.donationlistname span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.donationlist .donationitem span {
  font-size: 20px !important;
}

.donationlist .donationitem:hover {
  opacity: 0.7;
}

.forgot-link,
.forgot-link a {
  padding: 0 !important;
  text-align: right;
  display: inline-block !important;
  width: 100%;
}

.saved-card-main .mobile-text {
  display: none;
}

.common-alert,
.recurring-alert {
  border: none !important;
  border-radius: 32px 32px 0px 0px !important;
  margin-bottom: 5px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  position: relative;
}

.recurring-alert {
  margin-bottom: 0 !important;
}

.common-alert button,
.recurring-alert button {
  opacity: 1;
  height: 44px;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  padding: 12px !important;
}

.common-alert button span,
.recurring-alert button span {
  font-size: 30px;
  line-height: 20px;
  color: #3a4c63;
}

.card_no {
  margin-left: 5px;
}

.menu-list-icon {
  display: flex;
}

.react-swipeable-tab-panel {
  display: none;
}

.react-swipeable-tab-panel.true {
  display: block;
}

.follow-us-text {
  margin-top: 5px;
  display: inline-block;
}

.checkout-payment-icon {
  max-height: 30px !important;
}

/* Popup css */
.ReactModal__Content_inner .popup-title {
  display: inline-block;
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}

.reccuring-confirm .recurring-popup {
  opacity: 0;
}

.reccuring-confirm .ReactModalPortal:last-child .ReactModal__Overlay {
  background-color: transparent !important;
}

.set-recurring-msg-popup {
}

.menu-inner-button {
  right: 10px;
  left: auto !important;
  top: 10px !important;
}

.cvv-icon {
  margin: 0 5px;
  display: inline-block;
}

/* .success-login-popup img {
  max-height: 160px;
  max-width: 160px;
}
 */
/* footer css */

.footer-p {
  display: inline-block;
  max-width: 171px;
  margin: 0px 20px 0 0;
  line-height: 15px;
}

.footer-logo-p {
  display: inline-block;
  width: 100%;
  line-height: 15px;
}

/* Swetch css start */
.switch-container {
  float: right;
}

input[type='checkbox'].switch {
  position: absolute;
  opacity: 0;
}

input[type='checkbox'].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: #ccc;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type='checkbox'].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type='checkbox'].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type='checkbox'].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type='checkbox'].switch:checked + div {
  width: 52px;
  background-position: 0 0;
  background-color: #3b89ec;
}

input[type='checkbox'].switch + div {
  width: 52px;
  height: 28px;
  padding: 1px;
}

input[type='checkbox'].switch:checked + div {
  background-color: #715aff;
}

input[type='checkbox'].switch + div > div {
  float: left;
  width: 24px;
  height: 24px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
}

input[type='checkbox'].bigswitch.switch + div > div {
  width: 44px;
  height: 44px;
  margin-top: 1px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(24px, 0, 0);
  transform: translate3d(24px, 0, 0);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
}

/* Swetch css end */

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/* Payment Done animation css start */
.celebration .animated {
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.celebration .delay2 {
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  -o-animation-delay: 0.7s;
  animation-delay: 0.7s !important;
}
.celebration .animated.zoomIn {
  -webkit-animation-name: zoomIn;
  -moz-animation-name: zoomIn;
  -o-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.animated.zoomIn {
  -webkit-animation-name: zoomIn;
  -moz-animation-name: zoomIn;
  -o-animation-name: zoomIn;
  animation-name: zoomIn;
}
/* Payment Done animation css end */

.quote-text {
  position: relative;
}

.mainpart .recurring-card {
  max-height: 20px !important;
  max-width: 100%;
}

ul.social-group {
  margin-top: 15px;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* .social-icon {
  border-radius: 0px !important;
} */

/* .annual-giving {
  background: #F9FAFC;
  display: inline-block;
  width: 100%;
  padding: 20px;
} */

.social-group li {
  max-width: 48px;
  max-height: 48px;
}

.social-group li button {
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
}

.social-group li:last-child {
  margin-right: 0px !important;
}

.donation-count {
  margin: 0 !important;
}

.donation-count li {
  display: inline-block;
  width: 50%;
}

.donation-count li:first-child {
  border-right: 1px solid #eaebee;
}

ul.fb-security-point {
  /* max-width: 360px;
  display: inline-block; */
  margin-bottom: 0;
}

ul.fb-security-point li {
  position: relative;
  padding-left: 7px;
}

ul.fb-security-point li:after {
  position: absolute;
  left: 0;
  top: 1px;
  content: '•';
  height: 12px;
  width: 5px;
  font-size: 12px;
  color: #3a4c63;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table thead,
table tbody {
  width: 100%;
}

table tr td {
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #cad2db;
  color: #676e77;
  padding-top: 25px;
  padding-bottom: 25px;
  vertical-align: middle;
  box-shadow: inset 0 -1px 0 0px #fff;
  -webkit-box-shadow: inset 0 -1px 0 0px #fff;
  -moz-box-shadow: inset 0 -1px 0 0px #fff;
  -o-box-shadow: inset 0 -1px 0 0px #fff;
}

table tr:nth-child(2n + 0) td {
  background: #fcfcfc;
}

table tr td:nth-child(2) {
  color: #715aff;
  font-weight: 700;
  font-size: 18px;
}

table tr th {
  color: #282636;
  padding-bottom: 5px;
}

table tr td,
table tr th {
  text-align: right;
  padding-left: 24px;
  padding-right: 24px;
}

table tr td:first-child,
table tr th:first-child {
  text-align: left;
}

.recurring-box {
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  box-shadow: 2px 13px 16px rgba(58, 76, 99, 0.11);
  border-radius: 10px;
  padding: 15px;
}

.recurring-box .row {
  margin-bottom: 6px;
}

.react-swipeable-tab-iscroll-wrap .react-swipeable-tab-iscroll {
  position: unset !important;
}

.react-swipeable-tab-tabs .react-swipeable-tab-tabList {
  padding-right: 60px;
}

.react-swipeable-tab-tabList .react-swipeable-tab-iscroll-wrap {
  /* padding-right: 60px; */
  overflow: auto;
  height: 48px;
}

.tab-shadow .react-swipeable-tab-tabList .react-swipeable-tab-iscroll-wrap {
  box-shadow: -6px 0 5px -5px rgba(0, 0, 0, 0.3) inset;
}

.react-swipeable-tab-tabList
  .react-swipeable-tab-iscroll-wrap
  .react-swipeable-tab-iscroll {
  /* padding-right: 60px; */
  overflow: auto;
  height: 48px !important;
}

.react-swipeable-tab-panelList {
  border-top: 1px solid #c1c1c1;
}

.react-swipeable-tab-tab {
  margin: 0 !important;
  padding: 0 !important;
  text-align: left !important;
  /* max-width: 80px; */
}

.react-swipeable-tab-tab span {
  color: #3a4c63;
  display: inline-block;
  padding: 11px 10px !important;
  font-weight: 700;
  /* margin: 0 50px 0 0px; */
  margin-bottom: -2px;
  font-size: 18px !important;
  position: relative;
  width: auto !important;
  cursor: pointer;
}

.react-swipeable-tab-tab.active span {
  color: #715aff !important;
}

.react-swipeable-tab-tab.active span::after {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 0;
  background: #715aff;
  width: 100%;
  height: 4px;
  border-radius: 2px;
}

.react-swipeable-tab-tabList .ink {
  display: none;
}

.saved-card-main {
  display: inline-block !important;
  width: auto !important;
}
.saved-card-main > div:first-child {
  float: left;
}
.saved-card-main > div:last-child {
  float: right;
}

.similar-gift-popup .ReactModal__Content_inner {
  max-width: 380px;
}

.welcome-avtar img {
  width: 100%;
}

.preferred-card {
  min-height: 180px;
}

.amount-inner {
  display: inline-block;
  width: 100%;
}

/* historytab */
.historytab-main.owl-carousel {
  margin-top: -13px;
}
.historytab-main.owl-carousel .owl-item {
  text-align: center;
}
.historytab-main.owl-carousel .owl-nav {
  margin: 0;
}
.historytab-main.owl-carousel .owl-nav [class*='owl-'] {
  background-color: transparent !important;
  position: absolute;
  top: 10px;
  margin: 0;
}

.historytab-main.owl-carousel .owl-nav .owl-prev {
  left: -30px;
}

.historytab-main.owl-carousel .owl-nav .owl-next {
  right: -30px;
}

.historytab-main.owl-carousel .owl-nav [class*='owl-'] span {
  display: inline-block;
  height: 14px;
  width: 19px;
  font-size: 0;
}

.historytab-main.owl-carousel .owl-nav .owl-prev span {
  background: url('/public/images/svg/prev-slider-arrow.svg') no-repeat scroll 0
    0;
}

.historytab-main.owl-carousel .owl-nav .owl-next span {
  background: url('/public/images/svg/next-slider-arrow.svg') no-repeat scroll 0
    0;
}

/* Responsive css */

@media (min-width: 1200px) {
  .simple-card {
    min-height: 130px;
  }
}

@media (min-width: 1070px) {
  .container {
    padding: 0 !important;
    width: 940px !important;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .container {
    padding: 0 15px;
  }

  .simple-card {
    min-height: 115px;
  }
}

@media (max-width: 1070px) {
  .recurring-alert {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .simple-card {
    min-height: 110px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 100% !important;
  }

  .responsive-none {
    display: none;
  }

  .username {
    display: none;
    /* line-height: 32px !important; */
  }

  .up-down-arrow {
    /* padding-top: 11px; */
    padding-left: 8px;
  }

  .saved-card-main .desktop-text {
    display: none;
  }
  .saved-card-main .mobile-text {
    display: block;
  }

  .simple-card {
    min-height: 180px;
  }
}

@media (max-width: 767px) {
  body {
    padding-top: 12px;
  }
  .footer-right-part {
    float: none !important;
    display: inline-block;
    text-align: left;
  }
  .mainpart {
    padding: 0 15px;
  }
}

@media (max-width: 575px) {
  .donationlist .donationitem span {
    font-size: 16px !important;
  }

  table tr td,
  table tr th {
    text-align: right;
    padding-left: 14px !important;
    padding-right: 14px !important;
    font-size: 14px;
  }

  .footer-right-part {
    text-align: center;
  }

  .footer-inner-left-part {
    float: none !important;
  }

  .footer-p {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 10px;
    max-width: none;
  }

  .social-group li {
    margin-right: 25px !important;
  }

  .historytab-main.owl-carousel {
    padding: 0 25px;
  }
  .historytab-main.owl-carousel .owl-nav .owl-prev {
    left: 0;
  }
  .historytab-main.owl-carousel .owl-nav .owl-next {
    right: 0;
  }
}

@media (max-width: 375px) {
  .preferred-card {
    min-height: 156px;
  }
  .simple-card {
    min-height: 156px;
  }
}
